export enum KEY {
    WEEKLY_MENU_FORM = 'weekly-menu-form',
    LIST_DISH_OF_WEEK_RESPONSE = 'list-dish-of-week-response',
    USER_INFORMATION = 'user-information',
    LIST_ADDRESS_RESPONSE = 'list-address-response',
    ID_ADDRESS_SELECTED = 'id-address-selected',
    IS_SEARCHED = 'is-searched',
    IS_BUY_CLUB_TAPPERS = 'is-buy-club-tappers',
    IS_ACTIVE_CLUB_TAPPERS = 'is-active-club-tappers',
    PROPINA_PORCENTAGE = 'propina-porcentage',
    PROPINA = 'propina',
    DELIVERY_TYPE = 'delivery-type',
    IS_COUPON_DISCOUNT = 'is-coupon-discount',
    COUPON_DISCOUNT_AMOUNT = 'coupon-discount-amount',

    NIUBIZ_ACCESS_TOKEN = 'niubiz-access-token',
    NIUBIZ_SESSION_TOKEN = 'niubiz-session-token',
    NIUBIZ_AMOUNT = 'niubiz-amount',
    NIUBIZ_ORDER_ID = 'niubiz-order-id',

    TAPPERA_IMAGE = 'tappera-image',
    TAPPERA_DESCRIPTION = 'tappera-description'
}
