export const environment = {
  production: false,
  standalone: false,
  baseApi: 'https://pedidos-zona1.tappers.pe:3002/tappers/api',
  googleMapsApiKey: 'AIzaSyAJ4i2-W8XmTL1E7Qiq2fosseyDABLfTVE',
  googleMapsLibraries: ['places'],
  captchaV3: {
    siteKey: '6Lc94FcaAAAAADcPiz5PAgYoyoiL2UlBlLe6O3wF', //V2: '6LcermcaAAAAAPLSotoUKX26c_5GDaOTvoBTGLuZ'
  },
  delivery: {
    amount: {
      min: 3.5,
      max: 5.5
    },
    minimumAmountPerDay: 35
  },
  clubTappers: {
    membershipCost: 40,
    discountRate: 0.08, // 8%
    minimumAmountPerDay: 35
  },
  propina: {
    firstOption: 0.03, // 3%
    secondOption: 0.05, // 5%
    thirdOption: 0.10, // 10%
  },
  niubiz: {
    businessId:'650215477',
    channel: 'web',
    phpRedirectUrl: 'https://backoffice-zona1.tappers.pe',//'http://localhost:8081/backoffice/public'
  }
};
